<template>
	<el-dialog class="dialog" :title="title" :visible.sync="visiable">
		<div v-loading="loading" class="dlalog-body">
			<el-form ref="form" :rules="rules" :model="formData" label-width="120px">
				<el-row>
					<el-col :span="12">
						<el-form-item label="企业LOGO：">
							<el-upload class="avatar-uploader" action="" :show-file-list="false" :before-upload="BeforeUpload" :http-request="Upload">
								<el-image v-if="formData.logo" :src="uploadUrl + formData.logo" fit="fill" class="avatar"></el-image>
								<i v-else class="el-icon-plus avatar-uploader-icon"></i>
							</el-upload>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row>
					<el-col :span="12">
						<el-form-item label="企业全称：" prop="name"><el-input v-model="formData.name"></el-input></el-form-item>
					</el-col>
					<el-col :span="12">
						<el-form-item label="企业简称：" prop="abbr"><el-input v-model="formData.abbr"></el-input></el-form-item>
					</el-col>
				</el-row>
				<el-row>
					<el-col :span="12">
						<el-form-item label="数据库前缀：" prop="db_pre"><el-input v-model="formData.db_pre"></el-input></el-form-item>
					</el-col>
					<el-col :span="12">
						<el-form-item label="联系人：" prop="managerName"><el-input v-model="formData.manager_name"></el-input></el-form-item>
					</el-col>
				</el-row>
				<el-row>
					<el-col :span="12">
						<el-form-item label="联系电话：" prop="contact"><el-input v-model="formData.contact"></el-input></el-form-item>
					</el-col>
					<el-col :span="12">
						<el-form-item label="联系邮箱：" prop="email"><el-input v-model="formData.email"></el-input></el-form-item>
					</el-col>
				</el-row>
				<el-row>
					<el-col :span="24">
						<el-form-item label="企业地址：" prop="address">
							<el-col :span="9">
								<el-cascader
									class="cascader"
									v-model="provinces"
									clearable
									placeholder="请选择省/市/区"
									@change="CityChange"
									:props="{ value: 'name', label: 'name', children: 'child' }"
									:options="cityOptions"
								></el-cascader>
							</el-col>
							<el-col :span="15"><el-input v-model="formData.address"></el-input></el-col>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row>
					<el-col :span="24">
						<el-form-item label="备注：" prop="remarks"><el-input type="textarea" v-model="formData.remarks"></el-input></el-form-item>
					</el-col>
				</el-row>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button @click="close()">关闭</el-button>
				<el-button type="primary" @click="submit()">确定</el-button>
			</div>
		</div>
	</el-dialog>
</template>
<script>
import { dialogMixin } from '@m/dialogMixin';
import configApi from '@config/configApi';
export default {
	mixins: [dialogMixin],
	data() {
		return {
			title: '',
			loading: false,
			formData: {},
			provinces: [],
			cityOptions: [],
			rules: {
				name: {
					required: true,
					message: '此项必填',
					trigger: 'blur'
				},
				abbr: {
					required: true,
					message: '此项必填',
					trigger: 'blur'
				},
				db_pre: {
					required: true,
					message: '此项必填',
					trigger: 'blur'
				},
				contact: {
					required: true,
					validator: (rule, value, callback) => {
						const phoneReg = /^((0\d{2,3}-\d{7,8})|(1[3456789]\d{9}))$/;
						if (!value) {
							return callback(new Error('电话号码不能为空'));
						}
						setTimeout(() => {
							// if (!Number.isInteger(+value)) {
							// 	callback(new Error('请输入数字'));
							// } else {
								if (phoneReg.test(value)) {
									callback();
								} else {
									callback(new Error('电话号码格式不正确'));
								}
							// }
						}, 100);
					},
					trigger: 'blur'
				},
				email: {
					required: true,
					validator: (rule, value, callback) => {
						const mailReg = /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+(.[a-zA-Z0-9_-])+/;
						if (!value) {
							return callback(new Error('邮箱不能为空'));
						}
						setTimeout(() => {
							if (mailReg.test(value)) {
								callback();
							} else {
								callback(new Error('请输入正确的邮箱格式'));
							}
						}, 100);
					},
					trigger: 'blur'
				}
			},
			uploadUrl: configApi.photoURL,
			newFile: new FormData()
		};
	},
	methods: {
		//上传前校验
		BeforeUpload(file) {
			const isLt2M = file.size / 1024 / 1024 < 10;
			const fileType = file.name.substring(file.name.lastIndexOf('.'));
			if (fileType != '.jpg' && fileType != '.JPG' && fileType != '.PNG' && fileType != '.png') {
				this.$message.error('上传头像图片只能是 JPG,PNG 格式!');
			} else if (!isLt2M) {
				this.$message.error('上传头像图片大小不能超过 10MB!');
			} else {
				this.currentFileName = file.name;
				if (file) {
					if(this.newFile.has("file")){
						this.newFile.delete("file");
					}
					this.newFile.append('file', file);
					return true;
				} else {
					return false;
				}
			}
			return false;
		},
		//手动上传
		Upload(param) {
			this.loading = true;
			this.$upload(this.newFile)
				.then(res => {
					if (res.code == 1000) {
						this.formData.logo = res.data;
						this.loading = false;
						this.newFile = new FormData();
						this.$message.success(res.msg);
					} else {
						this.$message.error(res.msg);
					}
				})
				.catch(error => {
					this.$message.error(error);
				});
		},
		//打开弹窗
		open() {
			this.formData = {};
			this.provinces = [];
			this.cityOptions = [];
			this.get();
			this.$nextTick(() => {
				this.$refs.form.clearValidate();
			});
		},
		//获取数据
		get() {
			if (this.dialogObj.type == 0 || this.dialogObj.type == 1) {
				if (this.dialogObj.type == 0) {
					this.title = '查看';
				} else {
					this.title = '编辑';
				}
				this.$get('/backend-api/sys/ent/toUpdate/' + this.dialogObj.id).then(res => {
					if (res.code == 1000) {
						this.formData = res.data;
						if (this.dialogObj.type == 1) {
							this.getProvince(1);
						}
					} else {
						this.$message.error(res.msg);
					}
				});
			} else if (this.dialogObj.type == 2) {
				this.title = '添加';
				this.getProvince(0);
				// this.$get("/backend-api/sys/ent/toSave").then((res) => {
				// 	if (res.code == 1000) {
				// 		this.formData = res.data
				// 		this.getProvince(0);
				// 	} else {
				// 		this.$message.error(res.msg);
				// 	}
				// });
			}
		},
		//提交表单
		submit() {
			this.$refs['form'].validate(valid => {
				if (valid) {
					this.loading = true;
					if (this.dialogObj.type == 1) {
						this.$put('/backend-api/sys/ent/update', this.formData).then(res => {
							this.loading = false;
							if (res.code != 1000) {
								this.$message.error(res.msg);
								return;
							}
							this.$message.success(res.msg);
							this.$parent.list();
							this.close();
						});
					} else if (this.dialogObj.type == 2) {
						this.$post('/backend-api/sys/ent/save', this.formData).then(res => {
							this.loading = false;
							if (res.code != 1000) {
								this.$message.error(res.msg);
								return;
							}
							this.$message.success(res.msg);
							this.$parent.list();
							this.close();
						});
					}
				} else {
					return false;
				}
			});
		},
		CityChange(value) {
			this.formData.province = value[0];
			this.formData.city = value[1];
			this.formData.district = value[2];
		},
		// 获取省市区地址
		getProvince(e) {
			// 获取省市区地址
			this.$get('base-api/area/tree').then(data => {
				this.cityOptions = this.getTreeData(data.data);
				if (e == 1) {
					this.$nextTick(() => {
						let i, j, k;
						for (i = 0; i < this.cityOptions.length; i++) {
							if (this.cityOptions[i].name === this.formData.province) {
								this.$set(this.provinces, 0, this.cityOptions[i].name);
								break;
							}
						}
						for (j = 0; j < this.cityOptions[i].child.length; j++) {
							if (this.cityOptions[i].child[j].name === this.formData.city) {
								this.$set(this.provinces, 1, this.cityOptions[i].child[j].name);
								break;
							}
						}
						for (k = 0; k < this.cityOptions[i].child[j].child.length; k++) {
							if (this.cityOptions[i].child[j].child[k].name === this.formData.district) {
								this.$set(this.provinces, 2, this.cityOptions[i].child[j].child[k].name);
								break;
							}
						}
					});
				}
			});
		},
		// 递归判断列表，把最后的children设为undefined
		getTreeData(data) {
			for (var i = 0; i < data.length; i++) {
				if (data[i].child.length < 1) {
					// children若为空数组，则将children设为undefined
					data[i].child = undefined;
				} else {
					// children若不为空数组，则继续 递归调用 本方法
					this.getTreeData(data[i].child);
				}
			}
			return data;
		},
		//关闭弹窗
		close() {
			this.visiable = false;
		}
	}
};
</script>
<style lang="scss" scoped>
.dialog {
	::v-deep.cascader {
		display: block;
	}
}

::v-deep.avatar-uploader .el-upload {
	border: 1px dashed #d9d9d9;
	border-radius: 6px;
	cursor: pointer;
	position: relative;
	overflow: hidden;
}
::v-deep.avatar-uploader .el-upload:hover {
	border-color: #409eff;
}
.avatar-uploader-icon {
	font-size: 28px;
	color: #8c939d;
	width: 78px;
	height: 78px;
	line-height: 78px;
	text-align: center;
}
.avatar {
	width: 78px;
	height: 78px;
	display: block;
}
</style>
