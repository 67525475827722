<template>
	<el-dialog class="dialog" :title="title" :visible.sync="visiable">
		<div v-loading="loading" class="dlalog-body">
			<el-form ref="form" :model="formData" label-width="120px">
				<el-row v-if="formData.logo">
					<el-col :span="12">
						<el-form-item label="企业LOGO：">
							<el-image :src="uploadUrl+formData.logo" fit="fill" class="avatar">
							</el-image>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row>
					<el-col :span="12">
						<el-form-item label="企业全称："><el-input v-model="formData.name" disabled></el-input></el-form-item>
					</el-col>
					<el-col :span="12">
						<el-form-item label="企业简称："><el-input v-model="formData.abbr" disabled></el-input></el-form-item>
					</el-col>
				</el-row>
				<el-row>
					<el-col :span="12">
						<el-form-item label="数据库前缀："><el-input v-model="formData.db_pre" disabled></el-input></el-form-item>
					</el-col>
					<el-col :span="12">
						<el-form-item label="联系人："><el-input v-model="formData.manager_name" disabled></el-input></el-form-item>
					</el-col>
				</el-row>
				<el-row>
					<el-col :span="12">
						<el-form-item label="联系电话："><el-input v-model="formData.contact" disabled></el-input></el-form-item>
					</el-col>
					<el-col :span="12">
						<el-form-item label="联系邮箱："><el-input v-model="formData.email" disabled></el-input></el-form-item>
					</el-col>
				</el-row>
				<el-row>
					<el-col :span="24">
						<el-form-item label="企业地址："><el-input v-model="address" disabled></el-input></el-form-item>
					</el-col>
				</el-row>
				<el-row>
					<el-col :span="24">
						<el-form-item label="备注："><el-input type="textarea" v-model="formData.remarks" disabled></el-input></el-form-item>
					</el-col>
				</el-row>
			</el-form>
			<div slot="footer" class="dialog-footer"><el-button @click="close()">关闭</el-button></div>
		</div>
	</el-dialog>
</template>
<script>
import { dialogMixin } from '@m/dialogMixin';
import configApi from '@config/configApi';
export default {
	mixins: [dialogMixin],
	data() {
		return {
			title: '',
			loading: false,
			formData: {},
			provinces: [],
			cityOptions: [],
			uploadUrl:configApi.photoURL,
			address:''
		};
	},
	props: {},
	watch: {},
	methods: {
		//打开弹窗
		open() {
			this.formData = {};
			this.provinces = [];
			this.cityOptions = [];
			this.get();
		},
		//获取数据
		get() {
			if (this.dialogObj.type == 0 || this.dialogObj.type == 1) {
				if (this.dialogObj.type == 0) {
					this.title = '查看';
				} else {
					this.title = '编辑';
				}
				this.$get('/backend-api/sys/ent/toUpdate/' + this.dialogObj.id).then(res => {
					if (res.code == 1000) {
						this.formData = res.data;
						this.address =this.formData.province+this.formData.city+this.formData.district+this.formData.address
						if (this.dialogObj.type == 1) {
							this.getProvince(1);
						}
					} else {
						this.$message.error(res.msg);
					}
				});
			} else if (this.dialogObj.type == 2) {
				this.title = '添加';
				this.getProvince(0);
			}
		},
		//提交表单
		submit() {
			this.$refs['form'].validate(valid => {
				if (valid) {
					this.loading = true;
					if (this.dialogObj.type == 1) {
						this.$put('/backend-api/sys/ent/update', this.formData).then(res => {
							if (res.code == 1000) {
								this.loading = false;
								this.$message.success(res.msg);
								this.$parent.list();
								this.close();
							} else {
								this.$message.error(res.msg);
							}
						});
					} else if (this.dialogObj.type == 2) {
						this.$post('/backend-api/sys/ent/save', this.formData).then(res => {
							if (res.code == 1000) {
								this.loading = false;
								this.$message.success(res.msg);
								this.$parent.list();
								this.close();
							} else {
								this.$message.error(res.msg);
							}
						});
					}
				} else {
					return false;
				}
			});
		},
		CityChange(value) {
			this.formData.province = value[0];
			this.formData.city = value[1];
			this.formData.district = value[2];
		},
		// 获取省市区地址
		getProvince(e) {
			// 获取省市区地址
			this.$get('base-api/area/tree').then(data => {
				this.cityOptions = this.getTreeData(data.data);
				if (e == 1) {
					this.$nextTick(() => {
						let i, j, k;
						for (i = 0; i < this.cityOptions.length; i++) {
							if (this.cityOptions[i].name === this.formData.province) {
								this.$set(this.provinces, 0, this.cityOptions[i].name);
								break;
							}
						}
						for (j = 0; j < this.cityOptions[i].child.length; j++) {
							if (this.cityOptions[i].child[j].name === this.formData.city) {
								this.$set(this.provinces, 1, this.cityOptions[i].child[j].name);
								break;
							}
						}
						for (k = 0; k < this.cityOptions[i].child[j].child.length; k++) {
							if (this.cityOptions[i].child[j].child[k].name === this.formData.district) {
								this.$set(this.provinces, 2, this.cityOptions[i].child[j].child[k].name);
								break;
							}
						}
					});
				}
			});
		},
		// 递归判断列表，把最后的children设为undefined
		getTreeData(data) {
			for (var i = 0; i < data.length; i++) {
				if (data[i].child.length < 1) {
					// children若为空数组，则将children设为undefined
					data[i].child = undefined;
				} else {
					// children若不为空数组，则继续 递归调用 本方法
					this.getTreeData(data[i].child);
				}
			}
			return data;
		},
		//关闭弹窗
		close() {
			this.visiable = false;
		}
	}
};
</script>
<style lang="scss" scoped>
.dialog {
	::v-deep.cascader {
		display: block;
	}
}
.avatar {
	width: 78px;
	height: 78px;
	display: block;
}

</style>
